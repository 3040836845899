/* global maplibregl */

Apt.fn.make('maps', {
	/**
	 * Initialize module
	 *
	 * @param {$} target
	 * @param {Object} options
	 * @returns {Object}
	 */
	init: function(target, options) {
		var scope = this,
			priv = scope.$private;

		if (window.crawler || window.legacy || typeof maplibregl === 'undefined') {
			priv.unsupported(target);

			return scope;
		}

		if (scope.map) {
			return scope.position();
		}

		priv.working = true;

		priv.initMap(target, options);

		return scope;
	},

	/**
	 * Update map choropleth
	 */
	update: function() {
		var priv = this.$private;

		if (! priv.active) {
			return;
		}

		priv.layers = $.copy(priv.default.layers);

		priv.setRelations();
		priv.initTerritories();
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		this.$private.stash();
	}
}, {
	/**
	 * @private
	 */
	_construct: function() {
		var scope = this;

		scope.break = 8;

		scope.default = {
			bounds: [
				-124.80469, 25.32417,
				-66.97266, 49.03787
			],
			center: [
				-95.18223, 37.71207
			],
			zoom: {
				country: 3,
				locality: 11,
				postal_code: 12, // eslint-disable-line
				region: 7,
				territory: 6,
				subterritory: 9
			},
			minZoom: {
				locality: 9,
				region: 6,
				subterritory: 9,
				territory: 5
			},
			layers: {
				territories: {
					visible: [],
					groups: null
				},
				subterritories: {
					loaded: [],
					visible: [],
					groups: null
				},
				listings: []
			}
		};

		scope.overlays = {
			flood: {
				label: 'Flood plain',
				opacity: 0.6,
				tiles: 'https://enviroatlas.epa.gov/arcgis/rest/services/Supplemental/Estimated_floodplain_CONUS_WM/ImageServer/exportImage?f=image&bbox={bbox-epsg-3857}&imageSR=102100&f=image&format=png8&transparent=true&dpi=96'
			},
			hydro: {
				label: 'Water features',
				before: 'territories',
				maxzoom: 16,
				tiles: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSHydroCached/MapServer/tile/{z}/{y}/{x}'
			},
			public: {
				label: 'Public lands',
				opacity: 0.6,
				maxzoom: 13,
				tiles: 'https://services.arcgis.com/v01gqwM5QqNysAAi/ArcGIS/rest/services/PADUS3_0Manager_Type/MapServer/tile/{z}/{y}/{x}'
			}
		};

		scope.bases = {
			esri: {
				label: 'ESRI satellite',
				tiles: 'https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/satellite-esri/tiles/{z}/{x}/{y}?key=' + $.get('global.awsKey')
			},
			here: {
				label: 'HERE satellite',
				tiles: 'https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/satellite/tiles/{z}/{x}/{y}?key=' + $.get('global.awsKey')
			},
			mapbox: {
				label: 'Mapbox satellite',
				tiles: 'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.webp?access_token=' + $.get('global.mapboxKey')
			},
			naip: {
				label: 'NAIP satellite',
				tiles: 'https://api.mapbox.com/v4/mapbox.naip/{z}/{x}/{y}@2x.webp?access_token=' + $.get('global.mapboxKey'),
				minzoom: 12.5
			}
		};

		scope.empty = {
			type: 'FeatureCollection',
			features: []
		};

		scope.layers = $.copy(scope.default.layers);

		scope.setRelations();
	},

	/**
	 * Set location relation aliases
	 */
	setRelations: function() {
		var scope = this;

		scope.relation = {
			territories: scope.layers.territories, // eslint-disable-line
			subterritories: scope.layers.subterritories, // eslint-disable-line
			parcels: scope.layers.parcels // eslint-disable-line
		};
	},

	/**
	 * Setup new map
	 *
	 * @param {$} target
	 * @param {Object} options
	 */
	initMap: function(target, options) {
		var scope = this,
			pub = scope.$public,
			style = pub.getStyle(),
			loc = $.context('location', {}),
			position = pub.parsePosition(),
			center = position ? position.center : (
				options.center || loc.center || scope.default.center
			),
			defaultZoom = scope.default.zoom[loc.type || 'country'],
			zoom = position ? position.zoom : (
				options.zoom || (loc.bounds ?
					defaultZoom : (center ? 13.9 : defaultZoom + 0.5)
				)
			),
			conf = $.extend(true, {
				basic: false,
				points: false,
				preview: false,
				reset: options.single,
				single: false,
				settings: {
					dragRotate: ! options.preview,
					fadeDuration: 0,
					bounds: (position || options.center) ? null : (
						loc.bounds ? loc.bounds : (
							loc.center ?
								null : scope.default.bounds
						)
					),
					center: center,
					zoom: zoom
				},
				style: style
			}, options);

		if (! $(target).length) {
			return;
		}

		conf.target = target;

		scope.uid = LS.util.uid();
		scope.location = loc.slug;

		scope.conf = conf;

		if (options.bounds) {
			conf.settings.bounds = pub.parseBounds(options.bounds);
		} else if (conf.settings.bounds) {
			conf.settings.bounds = pub.padBounds(
				conf.settings.bounds, true
			);
		}

		if (conf.single && conf.center) {
			conf.showPoints = true;

			scope.layers.listings = [{
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'Point',
					coordinates: conf.center
				}
			}];
		} else if (conf.points) {
			conf.showPoints = true;

			scope.layers.listings = conf.points;
		}

		if (position) {
			scope.position = position;
		}

		scope.createMap();
	},

	/**
	 * Create new map
	 */
	createMap: function() {
		var scope = this,
			pub = scope.$public,
			conf = scope.conf;

		if (! pub.supported()) {
			scope.unsupported(conf.target);

			return;
		}

		scope.create(conf.target, conf.settings, function(instance) {
			scope.instance = instance;
			pub.map = scope.map = instance.map;

			scope.working = false;

			pub.bind('map', 'error', function(e) {
				if (! e.error || /(ajax|fetch|load|run)/i.test(e.error.message)) {
					return;
				}

				LS.error.report('MapError', e.error.message);
			});

			scope.addScale();

			// Enforce minimum zoom for locations
			if (scope.location && ! scope.position) {
				var min = scope.default.minZoom[$.context('location.type')];

				if (min && min > scope.map.getZoom()) {
					scope.map.jumpTo({
						center: conf.settings.bounds ?
							(new maplibregl.LngLatBounds(conf.settings.bounds)).getCenter() :
							conf.settings.center,
						zoom: min
					});
				}
			}

			if (! conf.showPoints) {
				scope.$parent.removeClass('-is-loading');
			}

			scope.initModel();

			pub.ready(function() {
				scope.setupMap();
			});
		});
	},

	/**
	 * Setup map data sources
	 */
	setupMap: function() {
		var scope = this,
			pub = scope.$public,
			conf = scope.conf,
			single = conf.single,
			build = ! single && ! conf.basic;

		if (! conf.showPoints) {
			scope.layers.listings = [];
		}

		scope.addSources();

		if (build) {
			scope.initTerritories();
		}

		scope.initMarkers();
		scope.initStyle();

		pub.ready(function() {
			if (conf.showPoints) {
				scope.addMarkers();

				if (! single) {
					scope.fitBounds(scope.layers.listings, {
						animate: false
					});

					scope.initial = {
						center: pub.getCenter(),
						zoom: pub.getZoom()
					};
				}

				scope.$parent.removeClass('-is-loading');
			}

			if (conf.ready) {
				conf.ready();
			}
		});

		if (build) {
			LS.util.idle([
				scope.bindHistory,
				scope.watchMove,
				scope.watchZoom
			], scope);
		}
	},

	/**
	 * Initialize map data sources
	 */
	addSources: function() {
		var scope = this;

		scope.addSource('points', {
			type: 'geojson',
			data: scope.empty
		});

		if (scope.conf.polygons) {
			scope.addSource('polygons', {
				type: 'geojson',
				data: scope.conf.polygons || scope.empty
			});

			scope.addLayer({
				id: 'polygons',
				type: 'line',
				source: 'polygons',
				minzoom: 14,
				paint: {
					'line-width': 2.5,
					'line-color': '#f2f9f5',
					'line-opacity': [
						'interpolate', ['linear'], ['zoom'],
						14, 0,
						14.1, 1
					]
				}
			});
		}
	},

	/**
	 * Fit map to feature boundaries
	 *
	 * @param {Array} points
	 * @param {Object} options
	 */
	fitBounds: function(points, options) {
		if (! points || ! points.length) {
			return;
		}

		var scope = this,
			pub = scope.$public,
			bounds = new maplibregl.LngLatBounds();

		points.forEach(function(feature) {
			bounds.extend(feature.geometry.coordinates);
		});

		pub.constrain(bounds, $.extend({
			animate: true,
			maxZoom: 15,
			pad: true
		}, options));
	},

	/**
	 * Filter only unique features
	 *
	 * @param {Array} features
	 * @param {String} [key='$id']
	 * @returns {Array}
	 */
	getUnique: function(features, key) {
		var ids = [];

		return features.filter(function(el) {
			var id = key ?
				el.properties[key] : el.id;

			if (ids.indexOf(id) > -1) {
				return false;
			}

			ids.push(id);

			return true;
		});
	},

	/**
	 * Render unsupported message
	 *
	 * @param {$} target
	 */
	unsupported: function(target) {
		$(target)
			.removeClass('-is-loading')
			.addClass('-is-disabled');
	}
});